import { IProps } from '../../interfaces/components/BrandLogo.interface';
import { getOemLogo } from '../../utils';

const unallowedBrandLogos = ['Audi', 'Volkswagen'];

const BrandLogo = ({ make, margin }: IProps) => {
  return (
    <div className="brand-logo" style={{ margin }}>
      {unallowedBrandLogos.includes(make) ? (
        <div className="logo-fallback">{make[0]}</div>
      ) : (
        <img src={getOemLogo(make)} alt={make} />
      )}
    </div>
  );
};

export default BrandLogo;
