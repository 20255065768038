import { NavLink, useNavigate } from 'react-router-dom';

import { IProps } from '../../../../interfaces/components/VehicleMenu.interface';
import staticText from '../../../../localization/app';
import { getYearModel } from '../../../../utils';
import BrandLogo from '../../../BrandLogo';

const VehicleMenu = ({ accounts, toggleMenu }: IProps) => {
  const navigate = useNavigate()
  const handleAddAccountClick = () => {
    toggleMenu();
    // TODO: relaunch connect instead of navigating
    navigate('/connect')
  };
  return (
    <div className="vehicle-menu">
      {Object.keys(accounts).map(accountKey => {
        const vehicles = accounts[accountKey];
        const make = vehicles[0].make;
        const username = vehicles[0].oemUsername;
        return (
          <div key={accountKey}>
            <div className="account-info">
              <div>
                <BrandLogo make={make} margin="0 8px 0 0" />
              </div>
              <div>
                <div className="account-info-make">{make}</div>
                <div className="account-info-username">{username}</div>
              </div>
            </div>
            <ul className="account-list">
              {vehicles.map(({ id, model, year }) => {
                return (
                  <li key={id}>
                    <NavLink
                      to={`/vehicles/${id}`}
                      onClick={toggleMenu}
                      className={`link vehicle-link ${({ isActive }) =>
                        isActive ? 'active' : ''}`}
                    >
                      <div>{getYearModel({ model, year })}</div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
      <div>
        <button
          type="button"
          onClick={handleAddAccountClick}
          className="add-vehicle-btn button link"
        >{`+ ${staticText.topbar.addVehicle}`}</button>
      </div>
    </div>
  );
};

export default VehicleMenu;
